import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface TaskAddRequiredErrorsType {
  task_name?: string,
  description?: string,
  userMembers?: string
}
import { generateRandomUsersWithLabel, generateTasks, TaskDataType, UserTypeWithLabel } from "./utils/generateTasks";
import { toast } from "react-toastify"
import dayjs, { Dayjs } from "dayjs"
import isBetween from 'dayjs/plugin/isBetween';
interface CreatedDateType {
  createdFrom: Dayjs | null,
  createdTo: Dayjs | null,
  editedFrom: Dayjs | null,
  editedTo: Dayjs | null,
}
interface LevelsType {
  level1: boolean,
  level2: boolean,
  level3: boolean,
  level4: boolean,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openRows: any
  selectedTasks: { [key: number]: boolean }
  task_name: string
  description: string
  errors: TaskAddRequiredErrorsType
  tasks: TaskDataType[]
  filteredTasks: TaskDataType[]
  currentPageIndex: number
  requiredPageSize: number
  sortField: string
  sortOrder: string
  searchQuery: string
  isDeleteTask: boolean
  taskId: string
  isAddUserModalOpen: boolean
  selectedUser: UserTypeWithLabel
  userMembers: UserTypeWithLabel[]
  dates: CreatedDateType
  levels: LevelsType
  isFilterModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cftasklistener3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openRows: {},
      selectedTasks: {},
      task_name: '',
      description: '',
      errors: {},
      tasks: [],
      filteredTasks: [],
      currentPageIndex: 0,
      requiredPageSize: 10,
      sortField: 'id',
      sortOrder: 'asc',
      searchQuery: '',
      isDeleteTask: false,
      taskId: '',
      isAddUserModalOpen: false,
      selectedUser: { user_name: '', source: "", label: '' },
      userMembers: [],
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      },
      levels: {
        level1: true,
        level2: false,
        level3: false,
        level4: false,
      },
      isFilterModal: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const tasks = generateTasks(30)
    this.setState({ tasks, filteredTasks: tasks, userMembers: generateRandomUsersWithLabel(10, 21) })
  }
  getNumericTaskId = (formattedId: any): number => {
    const match = formattedId.match(/\d+/);
    return match ? Number(match[0]) : NaN;
  };
  handleSortTasks = (field: string) => {
    const { sortField, sortOrder } = this.state;

    if (sortField === field) {
      this.setState({
        sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortField: field,
        sortOrder: 'asc',
      });
    }
  };
  onPageChangeTask = (page: number) => this.setState({ currentPageIndex: page })
  handlePreviousTask = () => {
    if (this.state.currentPageIndex > 0) this.onPageChangeTask(this.state.currentPageIndex - 1);
  };
  handlePageChangeTask = (event: any, value: number) => {
    this.setState({ currentPageIndex: value - 1 });
  };

  handleNextTask = (totalPages: number) => {
    if (this.state.currentPageIndex < totalPages - 1) this.onPageChangeTask(this.state.currentPageIndex + 1);
  };

  handleSortTaskData = () => {
    const { sortOrder, filteredTasks, sortField } = this.state;
    if (!Array.isArray(filteredTasks) || filteredTasks.length === 0) {
      return [];
    }

    const sortedData = [...filteredTasks].sort((a, b) => {

      let comparison = 0;

      if (sortField === 'id') {
        comparison = this.getNumericTaskId(a.task_id) - this.getNumericTaskId(b.task_id);
      } else if (sortField === 'name') {
        comparison = a.task_name.localeCompare(b.task_name);
      } else if (sortField === 'created_at' || sortField === 'updated_at') {
        const dateA = new Date(a[sortField]).getTime();
        const dateB = new Date(b[sortField]).getTime();
        comparison = dateA - dateB;
      }

      return sortOrder === 'asc' ? comparison : -comparison;
    });

    return sortedData
  };
  handleRowClick = (rowName: string) => {
    this.setState((prevState) => ({
      openRows: {
        ...prevState.openRows,
        [rowName]: !prevState.openRows[rowName],
      },
    }));
  };

  handleTaskCreate = () => {
    this.props.navigation.navigate("Createtaskgroup")
  }
  handleSearchTaskGroup = (query: string) => {
    this.setState({ searchQuery: query });
    const { tasks } = this.state
    if (query === '') {
      this.setState({ filteredTasks: tasks });
    }
    else {
      const filtered = tasks.filter(singleTask =>
        singleTask.task_name.toLowerCase().includes(query.toLowerCase()) ||
        singleTask.task_id.toString().includes(query.toUpperCase()) ||
        (singleTask.asso_pipelines &&
          singleTask.asso_pipelines.some(pipelineId =>
            pipelineId.label.toLowerCase().includes(query.toLowerCase())
          )
        ) || (singleTask.individualUser && singleTask.individualUser.some(user =>
          user.user_name.toLowerCase().includes(query.toLowerCase())
        ))
      );

      this.setState({ filteredTasks: filtered });
    }
  };
  handleTaskDetailView = (task: TaskDataType) => {
    localStorage.setItem("task", JSON.stringify(task))
    this.props.navigation.navigate("Detailtaskgroup", { taskId: task.task_id })
  }
  toggleTaskDeleteModal = () => {
    this.setState(prevState => ({ isDeleteTask: !prevState.isDeleteTask }))
  }
  handleDeleteTaskToggle = (id: string) => {
    this.toggleTaskDeleteModal()
    this.setState({ taskId: id })
  }
  handleDeleteSingleTask = (e: any) => {
    e.preventDefault()
    const updatedTasks = this.state.filteredTasks.filter(task => task.task_id !== this.state.taskId)
    this.setState({ filteredTasks: updatedTasks })
    toast.success("Task deleted successfully", { style: { fontSize: '1.6rem' } })
    this.toggleTaskDeleteModal()
  }
  toggleUserModal = () => {
    this.setState(prevState => ({ isAddUserModalOpen: !prevState.isAddUserModalOpen }))
  }
  handleUserModal = (item: TaskDataType) => {
    this.toggleUserModal()
    const existingUserIds = item.individualUser.map(user => user.user_name);
    const availableUsers = this.state.userMembers.filter(
      user => !existingUserIds.includes(user.user_name)
    );
    this.setState({ taskId: item.task_id, task_name: item.task_name, userMembers: availableUsers })
  }
  handleAddUser = (e: any) => {
    e.preventDefault();
    const { filteredTasks, taskId, selectedUser } = this.state;

    const updatedTasks = filteredTasks.map(task => {
      if (task.task_id === taskId) {
        return {
          ...task,
          individualUser: [...(task.individualUser || []), selectedUser]
        };
      }
      return task;
    });

    this.setState({ filteredTasks: updatedTasks, selectedUser: { user_name: "", source: "", label: "" } });
    toast.success("User added successfully", { style: { fontSize: '1.6rem' } });
    this.toggleUserModal();
  };

  handleUserSelect = (user: UserTypeWithLabel) => {
    this.setState({
      selectedUser: user
    });
  };
  handleLevelChange = (event: any) => {
    this.setState(prevState => ({ levels: { ...prevState.levels, [event.target.name]: event.target.checked } }));
  };
  resetFilters = () => {
    this.setState({
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      }
    });
    this.setState({
      levels: {
        level1: false,
        level2: false,
        level3: false,
        level4: false,
      }
    });
  };
  handleDateChange = (key: string, newValue: any) => {
    this.setState((prevState) => ({
      dates: {
        ...prevState.dates,
        [key]: newValue,
      },
    }));
  };
  toggleFilterModal = () => {
    this.setState(prevState => ({ isFilterModal: !prevState.isFilterModal }))
  }
  handleApplyTaskFilter = () => {
    const { tasks, dates, levels } = this.state;
    const { createdFrom, createdTo, editedFrom, editedTo } = dates;
    const filteredByLevels = tasks.filter((task) => {
      let isLevelMatch = true;

      const selectedLevels: string[] = [];
      if (levels.level1) selectedLevels.push('Level 1');
      if (levels.level2) selectedLevels.push('Level 2');
      if (levels.level3) selectedLevels.push('Level 3');
      if (levels.level4) selectedLevels.push('Level 4');

      const taskLevels = task.levels.map(level => level.label);
      if (selectedLevels.length > 0) {
        isLevelMatch = selectedLevels.every(level => taskLevels.includes(level))
      }
      return isLevelMatch;
    });

    dayjs.extend(isBetween);
    const filteredByDate = filteredByLevels.filter((task) => {
      const createdAt = dayjs(task.created_at);
      const updatedAt = dayjs(task.updated_at);

      let isCreatedInRange = true;
      let isEditedInRange = true;

      if (createdFrom && createdTo) {
        const createdFromDayjs = dayjs(createdFrom);
        const createdToDayjs = dayjs(createdTo);
        isCreatedInRange = createdAt.isBetween(createdFromDayjs, createdToDayjs, null, '[]');
      }

      if (editedFrom && editedTo) {
        const editedFromDayjs = dayjs(editedFrom);
        const editedToDayjs = dayjs(editedTo);
        isEditedInRange = updatedAt.isBetween(editedFromDayjs, editedToDayjs, null, '[]');
      }

      return isCreatedInRange && isEditedInRange;
    });
    this.setState({
      filteredTasks: filteredByDate
    });

    toast.success('Filters applied successfully', { style: { fontSize: "1.6rem" } });
    this.toggleFilterModal()
  };


  // Customizable Area End
}
