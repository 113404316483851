import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { generatePipeline, PipelineType } from './utils/generatePipeline';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs, { Dayjs } from "dayjs"
interface CreatedDateType {
  createdFrom: Dayjs | null,
  createdTo: Dayjs | null,
  editedFrom: Dayjs | null,
  editedTo: Dayjs | null,
}
interface ClinicsType {
  global: boolean,
  clinic1: boolean,
  clinic2: boolean,
  clinic3: boolean,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentPage: number,
  pageSize: number,
  rowData: PipelineType[],
  filteredData: PipelineType[],
  pipeline_id: number | null | string
  pipeline_name: string
  isDeletePipelineModal: boolean,
  searchQuery: string
  isFilterModal: boolean
  sortBy: string
  sortDirection: string;
  isPipelineDuplicateModal: boolean
  singlePiplineDuplicate: PipelineType
  openRows: any
  dates: CreatedDateType
  clinics: ClinicsType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfpipelines1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentPage: 0,
      pageSize: 10,
      rowData: [],
      filteredData: [],
      pipeline_id: null,
      pipeline_name: '',
      isDeletePipelineModal: false,
      searchQuery: '',
      isFilterModal: false,
      sortBy: '',
      sortDirection: '',
      openRows: {},
      isPipelineDuplicateModal: false,
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      },
      clinics: {
        global: true,
        clinic1: false,
        clinic2: false,
        clinic3: false,
      },
      singlePiplineDuplicate: { id: '', created_at: '', description: '', order: null, permission_level: '', pipeline_name: '', pipeline_serial_id: '', status: '', updated_at: '', stages: [], clinic_name: '', rules: [], visibleBy: { IndividualUsers: [], PermissionLevels: [], TaskGroups: [] } }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    const pipelines = generatePipeline(36);
    this.setState({ filteredData: pipelines, rowData: pipelines })
  }
  onPageChange = (page: number) => this.setState({ currentPage: page })
  handlePrevious = () => {
    if (this.state.currentPage > 0) this.onPageChange(this.state.currentPage - 1);
  };
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value - 1 });
  };

  handleNext = (totalPages: number) => {
    if (this.state.currentPage < totalPages - 1) this.onPageChange(this.state.currentPage + 1);
  };

  handlePipelineNavigate = (id: number | string) => {
    if (this.props.navigation.navigate) {
      this.props.navigation.navigate('PipelineDetail', { pipelineId: id })
    }
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({ isDeletePipelineModal: !prevState.isDeletePipelineModal }))
  }
  handleDeleteToggle = (id: number | string, pipeline_name: string) => {
    this.toggleDeleteModal()
    this.setState({ pipeline_id: id, pipeline_name })
  }
  toggleDuplicateModal = () => {
    this.setState(prevState => ({ isPipelineDuplicateModal: !prevState.isPipelineDuplicateModal }))
  }
  handleDuplicateToggle = (pipeline: PipelineType) => {
    this.toggleDuplicateModal()
    this.setState({ singlePiplineDuplicate: pipeline })
  }

  handleDeletePipeline = (event: any) => {
    event.preventDefault()
    const filteredRowData = this.state.filteredData.filter(item => item.id !== this.state.pipeline_id)
    this.setState({ filteredData: filteredRowData, isDeletePipelineModal: false })
    toast.success("Pipeline Deleted Successfully", { style: { fontSize: "1.6rem" } })
  }
  generateSecureId = () => {
    const uuidNumber = parseInt(uuidv4().replace(/\D/g, '').slice(0, 7), 10);

    const formattedId = `#P${uuidNumber.toString().padStart(7, '0')}`;

    return formattedId;
  };

  handleDuplicatePipeline = () => {
    const { singlePiplineDuplicate } = this.state;

    let newId: string;
    do {
      newId = this.generateSecureId();
    } while (this.state.rowData.some(pipeline => pipeline.id === newId));

    const newPipeline: PipelineType = {
      ...singlePiplineDuplicate,
      id: newId,
    };

    this.setState((prevState: any) => ({
      filteredData: [...prevState.rowData, newPipeline],
      isPipelineDuplicateModal: false,
    }));
    toast.success("Pipeline Duplicated Successully", { style: { fontSize: '1.6rem' } })
  };


  handleCreatePipeline = () => {
    this.props.navigation.navigate("CreatePipeline")
  }
  handleSearchPipeline = (query: string) => {
    this.setState({ searchQuery: query });
    const { rowData } = this.state
    if (query === '') {
      this.setState({ filteredData: rowData });
    }
    else {
      const filtered = rowData.filter(pipeline =>
        pipeline.pipeline_name.toLowerCase().includes(query.toLowerCase()) ||
        pipeline.id.toString().includes(query.toUpperCase()) ||
        pipeline.clinic_name.toLowerCase().includes(query.toLowerCase())
      );

      this.setState({ filteredData: filtered });
    }
  };
  toggleFilterModal = () => {
    this.setState(prevState => ({ isFilterModal: !prevState.isFilterModal }))
  }

  handleSortDirectionChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    this.setState({ sortDirection: newValue });
  };

  handleSortFieldChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    this.setState({ sortBy: newValue, sortDirection: '' });
  };

  handleSortChange = () => {
    const { sortDirection, rowData, sortBy } = this.state;
    const sortedData = [...rowData].sort((a, b) => {
      let comparison = 0;

      if (sortBy === 'id') {
        comparison = this.getNumericId(a.id) - this.getNumericId(b.id);
      } else if (sortBy === 'name') {
        comparison = a.pipeline_name.localeCompare(b.pipeline_name);
      } else if (sortBy === 'created_at' || sortBy === 'updated_at') {
        const dateA = new Date(a[sortBy]).getTime();
        const dateB = new Date(b[sortBy]).getTime();
        comparison = dateA - dateB;
      } else if (sortBy === 'asso_clinic') {
        comparison = a.clinic_name.localeCompare(b.clinic_name);
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });

    this.setState({
      filteredData: sortedData,
      isFilterModal: false,
    });
  };


  getNumericId = (formattedId: any): number => {
    const match = formattedId.match(/\d+/);
    return match ? Number(match[0]) : NaN;
  };

  getSortLabel = (direction: 'asc' | 'desc') => {
    const { sortBy } = this.state;

    if (sortBy === 'created_at' || sortBy === 'updated_at') {
      return direction === 'asc' ? 'Oldest' : 'Newest';
    }

    if (sortBy === 'name') {
      return direction === 'asc' ? 'A-Z' : 'Z-A';
    }

    if (sortBy === 'id') {
      return direction === 'asc' ? 'Lowest' : 'Highest';
    }

    return direction === 'asc' ? 'Ascending' : 'Descending';
  };
  handleDateChange = (key: string, newValue: any) => {
    this.setState((prevState) => ({
      dates: {
        ...prevState.dates,
        [key]: newValue,
      },
    }));
  };
  handleRowClick = (rowName: string) => {
    this.setState((prevState) => ({
      openRows: {
        ...prevState.openRows,
        [rowName]: !prevState.openRows[rowName],
      },
    }));
  };
  handleLevelChange = (event: any) => {
    this.setState(prevState => ({ clinics: { ...prevState.clinics, [event.target.name]: event.target.checked } }));
  };
  resetFilters = () => {
    this.setState({
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      }
    });
    this.setState({
      clinics: {
        global: false,
        clinic1: false,
        clinic2: false,
        clinic3: false,
      }
    });
  };
  handleApplyPipelineFilter = () => {
    const { rowData, dates, clinics } = this.state;
    const { createdFrom, createdTo, editedFrom, editedTo } = dates;
    const filteredByLevels = rowData.filter((pipeline) => {
      let isLevelMatch = true;

      const selectedClinics: string[] = [];
      if (clinics.global) selectedClinics.push('Global');
      if (clinics.clinic1) selectedClinics.push('Clinic 1');
      if (clinics.clinic2) selectedClinics.push('Clinic 2');
      if (clinics.clinic3) selectedClinics.push('Clinic 3');

      if (selectedClinics.length > 0) {
        isLevelMatch = selectedClinics.some(level => pipeline.clinic_name.includes(level))
      }
      return isLevelMatch;
    });

    dayjs.extend(isBetween);
    const filteredByDate = filteredByLevels.filter((task) => {
      const createdAt = dayjs(task.created_at);
      const updatedAt = dayjs(task.updated_at);

      let isCreatedInRange = true;
      let isEditedInRange = true;

      if (createdFrom && createdTo) {
        const createdFromDayjs = dayjs(createdFrom);
        const createdToDayjs = dayjs(createdTo);
        isCreatedInRange = createdAt.isBetween(createdFromDayjs, createdToDayjs, null, '[]');
      }

      if (editedFrom && editedTo) {
        const editedFromDayjs = dayjs(editedFrom);
        const editedToDayjs = dayjs(editedTo);
        isEditedInRange = updatedAt.isBetween(editedFromDayjs, editedToDayjs, null, '[]');
      }

      return isCreatedInRange && isEditedInRange;
    });
    const clinicOrder = ['Global', 'Clinic 3', 'Clinic 2', 'Clinic 1'];

    const sortedByClinicPriority = [...filteredByDate].sort((a, b) => {
        const aIndex = clinicOrder.indexOf(a.clinic_name);
        const bIndex = clinicOrder.indexOf(b.clinic_name);
        return aIndex - bIndex; 
    });
    this.setState({
      filteredData: sortedByClinicPriority
    });

    toast.success('Filters applied successfully', { style: { fontSize: "1.6rem" } });
    this.toggleFilterModal()
  };
  // Customizable Area End
}
