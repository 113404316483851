import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface EditTaskErrorType {
    group_name?: string
    description?: string
}
import { generateRandomUsersWithLabel, generateRandomPipelines, TaskDataType,UserTypeWithLabel } from "./utils/generateTasks";
import { toast } from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    taskId: string
    selectedPipelines: {label:string}[]
    singleTask: TaskDataType
    selectedUsers: UserTypeWithLabel[]
    allUsers: UserTypeWithLabel[]
    allPipelines: string[]
    selectedLevel: {label:string}[]
    description: string
    group_name: string
    editErrors: EditTaskErrorType
    levels: string[]
    // Customizable Area End
}

export interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class EdittaskgroupController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            taskId: '',
            allPipelines: [],
            selectedLevel: [],
            group_name: '',
            selectedUsers: [],
            selectedPipelines: [],
            singleTask: {task_id:'', task_name:"", task_desc:'', updated_at:"", created_at: "", levels:[],asso_pipelines:[],individualUser:[]},
            description: '',
            editErrors: {},
            levels: ["Level 1", "Level 2", "Level 3", "Level 4"],
            allUsers: generateRandomUsersWithLabel(7,14),
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const allPipelines = generateRandomPipelines(20)
        const singleTask: TaskDataType = JSON.parse(localStorage.getItem("task") || "{}") 
        this.setState({ allPipelines,singleTask, group_name: singleTask.task_name, taskId: singleTask.task_id, description: singleTask.task_desc, selectedLevel:singleTask.levels, selectedPipelines: singleTask.asso_pipelines, selectedUsers: singleTask.individualUser })
    }

    handleEditTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        this.setState((prevState) => {
            const updatedErrors = { ...prevState.editErrors };

            if (name === "group_name" && value.length <= 100) {
                delete updatedErrors.group_name;
            }

            if (name === "description" && value.length <= 300) {
                delete updatedErrors.description;
            }

            return {
                ...prevState,
                [name]: value,
                errors: updatedErrors,
            };
        });
    };

    handleChangeEditTaskAutoComplete = (name: string) => (event: any, newValue: any) => {
        this.setState((prev) => {
            return {
                ...prev,
                [name]: newValue
            }
        });
    }
    validateTaskEditFields = (group_name: string, task_description: string) => {
        const errors: EditTaskErrorType = {};

        if (group_name.length > 100) {
            errors.group_name = "Task group name cannot exceed 100 characters.";
        }

        if (task_description.length > 300) {
            errors.description = "Task group description cannot exceed 300 characters.";
        }

        return errors;
    };

    handleEditTaskGroup = () => {
        const { group_name, description, singleTask, selectedLevel, selectedPipelines, selectedUsers } = this.state
        const addStageErrorMessages = this.validateTaskEditFields(group_name, description);

        if (Object.keys(addStageErrorMessages).length > 0) {
            this.setState({ editErrors: addStageErrorMessages });
            return;
        }
        const updatedTask = {...singleTask}
        updatedTask.task_name= group_name
        updatedTask.task_desc = description
        updatedTask.asso_pipelines= selectedPipelines
        updatedTask.individualUser =selectedUsers
        updatedTask.levels= selectedLevel
        localStorage.setItem("task",JSON.stringify(updatedTask))
        toast.success("Task Edited Successfully",{style:{fontSize:"1.6rem"}})
        this.props.navigation.goBack()
    }
    isEditTaskComplete = () => {
        const { group_name, description } = this.state
        return (
            group_name &&
            description
        );
    }
    // Customizable Area End
}
