import React from "react";
// Customizable Area Start
import Custompipelinecomp from "./utils/Custompipelinecomp.web";
// Customizable Area End

import EditpipelineController, {
    Props,
} from "./EditpipelineController";

export default class Editpipeline extends EditpipelineController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { description, pipeline_name, editLevelsData,editPipelineErrors, editTasksData, editUsersData, selectedLevels, selectedTasks, selectedUsers, associatedClinic } = this.state
        return (
            <Custompipelinecomp
                navigation={this.props.navigation}
                id="Editpipeline"
                description={description}
                handleChangeValue={this.handleEditChangeValue}
                pipeline_name={pipeline_name}
                handleCreateEditPipeline={this.handleEditPipeline}
                title="Edit Pipeline"
                handleAutoComplete={this.handleChangeEditAutoComplete}
                allLevels={editLevelsData}
                allUsers={editUsersData}
                allTasks={editTasksData}
                customSelectedLevels={selectedLevels}
                customSelectedUsers={selectedUsers}
                customSelectedTasks={selectedTasks}
                customAssociatedClinic ={associatedClinic}
                handleCustomSelectClinic ={this.handleEditClinicSelect}
                isCustomFormComplete ={this.isEditPipelineFormComplete}
                buttonText="Save Changes"
                customPipelinesError={editPipelineErrors}
            />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End
