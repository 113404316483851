import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { PipelineType, StagesDataType } from './utils/generatePipeline';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pipelineData: PipelineType[]
  singlePipeline: PipelineType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PipelineDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  dragIndexGlobal: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pipelineData: [],
      singlePipeline: {
        visibleBy: {
          TaskGroups: [], IndividualUsers: [],
          PermissionLevels: [],
        },
        order: null,
        stages: [],
        clinic_name: '',
        permission_level: '',
        pipeline_serial_id: '',
        pipeline_name: '',
        description: '',
        created_at: '',
        rules: [],
        id: '',
        updated_at: '',
        status: '',
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const storedPipeline = JSON.parse(localStorage.getItem('pipeline') || "{}")
    this.setState({ singlePipeline: storedPipeline })
  }

  handleStageView = (item: StagesDataType) => {
    localStorage.setItem("stage", JSON.stringify(item));
    this.props.navigation.navigate("Cfstage1details", { stageId: item.id, pipelineId: item.pipeline_id })
  }
  handlePipelineEdit = () => {
    this.props.navigation.navigate("Editpipeline", { pipelineId: this.state.singlePipeline.id })
  }

  handleBackClick = (sliderRef: any) => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  handleNextClick = (sliderRef: any) => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  handleDrag = (e: any, dragIndex: number) => {
    this.dragIndexGlobal = dragIndex
  }
  handleDrop = (e: any, dropIndex: number) => {
    e.preventDefault()
    this.setState(prevState => {
      const { stages } = prevState.singlePipeline;
      const oldValue = stages[this.dragIndexGlobal];
      const newValue = stages[dropIndex];
      const updatedStages = [...stages];
      updatedStages[this.dragIndexGlobal] = newValue;
      updatedStages[dropIndex] = oldValue;
      return {
        singlePipeline: {
          ...prevState.singlePipeline,
          stages: updatedStages,
        },
      }
    })
  }
  // Customizable Area End
}
